<template>
  <section class="section p-5" v-if="!hideHtml">
    <div>
      <app-logo class="my-5"/>
      <div class="mb-3">
        <h5 class="title is-5 pt-5 mb-1">{{ $t('account.createUser') }}</h5>
        <p>{{$t('account.selectAvatarAndName')}}</p>
      </div>
      <div class="fill-strech hmt-5">

        <!-- avatar  -->
        <b-field class="avatarSelector mb-3">
          <b-radio v-model="avatar" name="avatar" v-for="(av, i) in $store.state.avatars" :key="i" :native-value="i">
            <div class="playerAvatar" :class="{ selected: i == avatar }" :style="{ backgroundColor: `#${color}` }">
              <img :src="av.image.sizes.thumbnail.url" />
            </div>
          </b-radio>
        </b-field>

        <!-- color -->
        <b-field class="mb-5">
          <input type="range" min="0" max="360" step="1" value="50" class="slider" id="hueSlider" v-model="hueVal">
        </b-field>

        <!-- username -->
        <b-field>
          <b-input
            v-model="username"
            :placeholder="$t('account.usernamePlaceholder')"
            maxlength="25"
            expanded
            :autofocus="true"
            :class="{'border-radius': !sugestRandomUsername}"
            
            pattern="(?!.*\s$)(?=.*[a-zA-Z].*[a-zA-Z])^[a-zA-Z0-9\s\u00C0-\u024F]+$"
            required
            validation-message="At least 2 characters long and no special symbols"
            ref="usernameField" 
            :loading="fetchingRandomUsername"
            /> 
            <!-- El regex valida: que el texto no comience ni termine con espacios, que no contenga caracteres especiales y que contenga al menos 2 letras -->
            <!-- TODO Cambiar setCustomValidity para que sea traducible -->
          <p class="control">
            <b-button v-if="sugestRandomUsername" type="is-primary" @click="Randomize()">
              <d-icon pack="fas" icon="shuffle" size="is-small"/>
            </b-button>
          </p>
        </b-field>

        <b-button
          :label="playButtonLabel"
          type="is-primary"
          expanded
          :disabled="ValidateUsername()"
          @click="Register()" />
        </div>
      <div class="m-3 has-text-centered">
        {{$t('account.alreadyHaveAccount')}}
        <a @click="GoToLogin()" class="goLink has-text-black-bis has-text-weight-bold">
          {{$t('account.login')}}</a>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { hsv2hex } from "color-functions"

const ACCOUNTSTATE = Object.freeze({
  inactive: 0,
  login: 1,
  register: 2,
  verify: 3,
});

export default {
  props: {},
  data() {
    return {
      sugestRandomUsername: false, 
      username: "",
      fakeUsername: "",
      acceptTerms: false,
      avatar: 0,
      color: "fcfcf0",
      hueVal: 100,
      fetchingRandomUsername: false,
      hideHtml: true,
    };
  },
  computed: {
    ShowMe() {
      return this.$store.state.user.accountState == ACCOUNTSTATE.register;
    },
    createdUsername() {
      return this.username.length > 1;
    },
    playButtonLabel() {
      return this.createdUsername ? this.$t('account.buttonPlayAsUsername', [this.username]) : this.$t('account.buttonPlayGuest');
    },
  },
  methods: {
    async FetchRandomUsername(){
      return new Promise((resolve) => {
        if (!this.sugestRandomUsername){
          resolve();
        }
        this.fetchingRandomUsername = true
        axios.get(process.env.VUE_APP_API_BASE + `/api/globals/setup/randomusername`)
          .then(res => {
            this.username = res.data.username
            this.fetchingRandomUsername = false
            resolve();
          })
          .catch(err => {
            console.warn(err)
            setTimeout(() => {
              this.FetchRandomUsername()
            }, 1500);
          });
      })
    },
    clearIconClick(){
      this.username = ""
    },
    ValidateUsername(){
      return !this.username.match(/(?!.*\s$)(?=.*[a-zA-Z].*[a-zA-Z])^[a-zA-Z0-9\s\u00C0-\u024F]+$/g) || this.username.length < 2 || this.username.indexOf('@') != -1
    },
    Register() {
      this.$store.dispatch("user/CreateGuestUser", { username: this.username, color: this.color, avatar: this.avatar });
    },
    GoToLogin() {
      // this.$store.commit("user/SetAccountState", 1); // login
      this.$router.push("/login")
    },
    Randomize() {
      return new Promise((resolve) => {
        this.FetchRandomUsername()
        .then(() => {
          this.avatar = Math.floor(Math.random() * (this.$store.state.avatars.length - 2) + 1)
  
          this.hueVal = Math.floor(Math.random() * 360)
  
          this.color = hsv2hex(
            this.hueVal,
            this.$store.getters["user/ColorSaturation"],
            this.$store.getters["user/ColorBrightness"]
          ).substring(1);
          resolve();
        })
        
      })
    }
  },
  watch: {
    username() {
      Vue.nextTick(() => {
        this.username = this.username.trim()
      })
    },
    hueVal(newVal) {
      newVal = parseInt(newVal)
      this.color = hsv2hex(
        newVal,
        this.$store.getters["user/ColorSaturation"],
        this.$store.getters["user/ColorBrightness"]
      ).substring(1);
    },
  },
  async beforeMount(){
    this.sugestRandomUsername = !this.$store.state.setup.registration.dontSugestRandomUsername;
    await this.Randomize()
    
    let skipUserCreation = this.$store.state.setup.userCreation?.skipUserCreation;
    let userClosedSession = localStorage.getItem("userClosedSession") == 1;
    console.log("skipUserCreation", skipUserCreation)
    console.log("userClosedSession", userClosedSession)
    if(skipUserCreation && !userClosedSession){
      this.hideHtml = true;
      // Skip user creation
      this.Register();
    }

    if(userClosedSession || !skipUserCreation){
      this.hideHtml = false;
      await this.Randomize()
    }
    localStorage.setItem("userClosedSession", false);
  },
  mounted() {
  }
};
</script>
<style lang="scss" scoped>
.section > div {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  height: 100%;
}

.fill-strech {
  flex: 1 1 auto;
}

.goLink { 
  display: inline-block;
}
.control.is-loading::after{
  top: 13px;
}
</style>